<template>
  <aForm :form="form" @submit="handleSubmit">
    <aRow :gutter="20">
      <aCol :span="18">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"> Nome </label>

          <aInput
            class="travel-input"
            placeholder="Escreva um nome para o evento/atividade"
            :disabled="disableEventFields"
            v-decorator="[
              `name`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </aInput>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"> Tipo de atividade </label>

          <aSelect
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            :disabled="disableEventFields"
            v-decorator="[
              `type`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <aSelectOption
              v-for="(item, index) of eventsType"
              :key="index"
              :value="item.name"
              :txt="item.name"
            >
              {{ item.name }}
            </aSelectOption>
          </aSelect>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"> Status </label>

          <aSelect
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            :disabled="disableEventFields"
            v-decorator="[
              `status`,
              {
                initialValue: 'Em aberto',
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            style="width: 100%"
          >
            <aSelectOption
              v-for="(item, index) of status"
              :key="index"
              :value="item.value"
              :txt="item.value"
            >
              {{ item.value }}
            </aSelectOption>
          </aSelect>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"
            >Data {{ form.getFieldValue(`type`) }}</label
          >
          <a-date-picker
            class="travel-input"
            :placeholder="`Selecione`"
            format="DD/MM/YYYY"
            :showToday="false"
            :allowClear="true"
            v-mask="'##/##/####'"
            :disabled="disableEventFields"
            v-decorator="[
              `date`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-date-picker>
        </aFormItem>
      </aCol>

      <aCol v-if="form.getFieldValue(`type`) == 'Férias'" :span="6">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"
            >Data Final {{ form.getFieldValue(`type`) }}</label
          >
          <a-date-picker
            class="travel-input"
            :placeholder="`Selecione`"
            :disabled="disableEventFields"
            format="DD/MM/YYYY"
            :showToday="false"
            :allowClear="true"
            v-mask="'##/##/####'"
            v-decorator="[
              `end_date`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </a-date-picker>
        </aFormItem>
      </aCol>

      <aCol
        v-if="$root.isAdmin() && form.getFieldValue(`type`) != 'Evento Pessoal'"
        :span="6"
      >
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"> Empresa </label>

          <aSelect
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            @change="getCompanyBranchesOnChange"
            :disabled="disableEventFields"
            v-decorator="[
              `company_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <aSelectOption
              v-for="(item, index) of companiesList"
              :key="index"
              :value="item.id"
              :txt="item.trading_name"
            >
              {{ item.id }} - {{ item.trading_name }}
            </aSelectOption>
          </aSelect>
        </aFormItem>
      </aCol>

      <aCol
        v-if="$root.isAdmin() && form.getFieldValue(`type`) != 'Evento Pessoal'"
        :span="6"
      >
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'"> Filial </label>

          <aSelect
            class="travel-input"
            placeholder="Selecione"
            optionFilterProp="txt"
            :disabled="companyBranchesList.length == 0 || disableEventFields"
            v-decorator="[
              `company_branch_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
            show-search
            style="width: 100%"
          >
            <aSelectOption
              v-for="(item, index) of companyBranchesList"
              :key="index"
              :value="item.id"
              :txt="item.name"
            >
              {{ item.id }} - {{ item.name }}
            </aSelectOption>
          </aSelect>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'">Vencimento (opcional)</label>
          <aDatePicker
            class="travel-input"
            :placeholder="`Selecione`"
            format="DD/MM/YYYY"
            :showToday="false"
            :allowClear="true"
            :disabled="disableEventFields"
            v-mask="'##/##/####'"
            v-decorator="[
              `due_date`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </aDatePicker>
        </aFormItem>
      </aCol>

      <aCol :span="6">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'">Data da competência (opcional)</label>
          <aDatePicker
            class="travel-input"
            :placeholder="`Selecione`"
            format="DD/MM/YYYY"
            :showToday="false"
            :allowClear="true"
            :disabled="disableEventFields"
            v-mask="'##/##/####'"
            v-decorator="[
              `competence_date`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
            <aIcon slot="prefix" type="field-svg" />
          </aDatePicker>
        </aFormItem>
      </aCol>

      <aCol :span="24">
        <EditEventModulesSection
          v-if="form.getFieldValue(`type`)"
          :form="form"
          :edit="edit"
          :event="event"
          :productSupplier="productSupplier"
          :disableEventFields="disableEventFields"
        />
      </aCol>

      <aCol :span="24">
        <aFormItem class="travel-input-outer">
          <label for :class="'filled'" style="top: -50px">
            Descrição (opcional)
          </label>

          <aInput
            class="travel-textarea"
            type="textarea"
            placeholder="Escreva aqui..."
            :disabled="disableEventFields"
            v-decorator="[
              `description`,
              {
                rules: [
                  {
                    required: false,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]"
          >
          </aInput>
        </aFormItem>
      </aCol>

      <template
        v-if="
          form.getFieldValue(`type`) == 'Pagamento' ||
          form.getFieldValue(`type`) == 'Recebimento' ||
          form.getFieldValue(`type`) == 'Férias'
        "
      >
        <aCol :span="24">
          <h2>Dados do Pagamento</h2>
        </aCol>

        <aCol :span="8">
          <aFormItem class="travel-input-outer">
            <label for class="filled">Valor (R$)</label>
            <aInput
              class="travel-input"
              :placeholder="`Valor do ${
                form.getFieldValue(`type`) ? form.getFieldValue(`type`) : '...'
              } (R$)`"
              v-currency
              :disabled="disableEventFields"
              v-decorator="[
                `value`,
                {
                  rules: [
                    {
                      required:
                        form.getFieldValue(`type`) == 'Férias' ? false : true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <aIcon slot="prefix" type="field-svg" />
            </aInput>
          </aFormItem>
        </aCol>

        <aCol :span="8">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Formas de Pagamento </label>

            <aSelect
              class="travel-input"
              placeholder="Formas de Pagamento"
              optionFilterProp="txt"
              :disabled="disableEventFields"
              v-decorator="[
                `payment_methods`,
                {
                  rules: [
                    {
                      required:
                        form.getFieldValue(`type`) == 'Férias' ? false : true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
              @change="onSelectPaymentMethods"
            >
              <aSelectOption
                v-for="(item, index) of paymentMethods"
                :key="index"
                :value="item.name"
                :txt="item.name"
              >
                {{ item.name }}
              </aSelectOption>
            </aSelect>
          </aFormItem>
        </aCol>

        <aCol
          v-if="
            ['Pagamento', 'Recebimento', 'Férias'].includes(
              form.getFieldValue('type')
            )
          "
          :span="8"
        >
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Banco </label>

            <aSelect
              class="travel-input"
              placeholder="Busque um banco"
              optionFialterProp="txt"
              :disabled="disableEventFields"
              v-decorator="[
                `bank_id`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <aSelectOption
                v-for="(item, index) of companyBanksList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.company_name }} ({{ item.account }})
              </aSelectOption>
            </aSelect>
          </aFormItem>
        </aCol>

        <aCol
          v-if="
            (form.getFieldValue(`type`) == 'Pagamento' &&
              form.getFieldValue(`payment_methods`) == 'Cartão de Crédito') ||
            (form.getFieldValue(`type`) == 'Recebimento' &&
              form.getFieldValue(`payment_methods`) == 'Cartão de Crédito') ||
            (form.getFieldValue(`type`) == 'Férias' &&
              form.getFieldValue(`payment_methods`) == 'Cartão de Crédito')
          "
          :span="8"
        >
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Cartão de Crédito </label>

            <aSelect
              class="travel-input"
              placeholder="Busque um cartão"
              optionFialterProp="txt"
              :disabled="disableEventFields"
              v-decorator="[
                `company_credit_card_id`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <aSelectOption
                v-for="(item, index) of companyCreditCardList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} -
                {{ item.customer.first_name }}
                {{ item.customer.last_name }} ({{ item.card_number }})
              </aSelectOption>
            </aSelect>
          </aFormItem>
        </aCol>

        <aCol
          v-if="
            ['Pagamento', 'Recebimento'].includes(form.getFieldValue(`type`))
          "
          :span="8"
        >
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Fornecedor </label>

            <aSelect
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              :disabled="disableEventFields"
              v-decorator="[
                `product_supplier_id`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <aSelectOption
                v-for="(item, index) of productSupplier.list"
                :key="index"
                :value="item.id"
                :txt="item.trading_name"
              >
                {{ item.id }} - {{ item.trading_name }}
              </aSelectOption>
            </aSelect>
          </aFormItem>
        </aCol>

        <aCol
          v-if="
            ['Pagamento', 'Recebimento'].includes(form.getFieldValue(`type`))
          "
          :span="8"
        >
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Voyage ID </label>

            <aInput
              class="travel-input"
              placeholder=" Voyage ID (opcional)"
              :disabled="disableEventFields"
              v-decorator="[
                `voyage_id`,
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <aIcon slot="prefix" type="field-svg" />
            </aInput>
          </aFormItem>
        </aCol>

        <aCol :span="8">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Prioridade </label>

            <aSelect
              class="travel-input"
              placeholder="Prioridade"
              optionFilterProp="txt"
              :disabled="disableEventFields"
              v-decorator="[
                `priority`,
                {
                  initialValue: 'Média',
                  rules: [
                    {
                      required:
                        form.getFieldValue(`type`) == 'Férias' ? false : true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <aSelectOption
                v-for="(item, index) of priorities"
                :key="index"
                :value="item.value"
                :txt="item.value"
              >
                {{ item.value }}
              </aSelectOption>
            </aSelect>
          </aFormItem>
        </aCol>
      </template>

      <aCol
        v-if="
          form.getFieldValue(`type`) == 'Pagamento' ||
          form.getFieldValue(`type`) == 'Recebimento' ||
          form.getFieldValue(`type`) == 'Férias'
        "
        class="mb-20"
        :span="24"
      >
        <aCollapse
          class="extra-event"
          v-model="activeCollapse"
          expandIconPosition="right"
        >
          <aCollapse-panel
            v-if="edit && attachment"
            key="2"
            :header="
              form.getFieldValue(`type`) == 'Pagamento' ||
              form.getFieldValue(`type`) == 'Recebimento' ||
              form.getFieldValue(`type`) == 'Férias'
                ? `ANEXO: A Pagar`
                : `ANEXO 1`
            "
            :disabled="false"
            forceRender
          >
            <aCol class="mb-20" :span="24">
              <div v-if="attachment" class="file-link relative">
                <a v-if="attachment" :href="attachment" target="_blank">
                  <img
                    v-if="checkFileType(attachment)"
                    :src="attachment"
                    style="max-width: 100px"
                    alt="img"
                  />
                  <aIcon
                    v-else
                    class="cblue"
                    type="paper-clip"
                    style="font-size: 38px"
                  />
                </a>
              </div>
            </aCol>
          </aCollapse-panel>

          <aCollapse-panel
            v-if="
              edit &&
              extraAttachment &&
              (form.getFieldValue(`type`) == 'Pagamento' ||
                form.getFieldValue(`type`) == 'Recebimento')
            "
            key="3"
            :header="
              form.getFieldValue(`type`) == 'Pagamento' ||
              form.getFieldValue(`type`) == 'Recebimento'
                ? `ANEXO: Comprovante Pagamento`
                : `ANEXO 2`
            "
            :disabled="false"
            forceRender
          >
            <aCol class="mb-20" :span="24">
              <div v-if="extraAttachment" class="file-link relative">
                <a
                  v-if="extraAttachment"
                  :href="extraAttachment"
                  target="_blank"
                >
                  <img
                    v-if="checkFileType(extraAttachment)"
                    :src="extraAttachment"
                    style="max-width: 100px"
                    alt="img"
                  />
                  <aIcon
                    v-else
                    class="cblue"
                    type="paper-clip"
                    style="font-size: 38px"
                  />
                </a>
              </div>
            </aCol>
          </aCollapse-panel>

          <aCollapse-panel
            v-if="
              edit &&
              extraAttachment2 &&
              (form.getFieldValue(`type`) == 'Pagamento' ||
                form.getFieldValue(`type`) == 'Recebimento')
            "
            key="4"
            :header="
              form.getFieldValue(`type`) == 'Pagamento' ||
              form.getFieldValue(`type`) == 'Recebimento'
                ? `ANEXO: NFe`
                : `ANEXO 3`
            "
            :disabled="false"
            forceRender
          >
            <aCol class="mb-20" :span="24">
              <div v-if="extraAttachment2" class="file-link relative">
                <a
                  v-if="extraAttachment2"
                  :href="extraAttachment2"
                  target="_blank"
                >
                  <img
                    v-if="checkFileType(extraAttachment2)"
                    :src="extraAttachment2"
                    style="max-width: 100px"
                    alt="img"
                  />
                  <aIcon
                    v-else
                    class="cblue"
                    type="paper-clip"
                    style="font-size: 38px"
                  />
                </a>
              </div>
            </aCol>
          </aCollapse-panel>
        </aCollapse>
      </aCol>

      <aCol class="a-center mt-10 mb-20" :span="24">
        <aButton
          type="primary"
          :loading="loadingCreateEvent"
          html-type="submit"
          :disabled="false"
        >
          {{ !edit ? "Criar atividade" : "Atualizar atividade" }}
        </aButton>
      </aCol>
    </aRow>
  </aForm>
</template>

<script>
import _ from "lodash";

import userMixins from "@/mixins/user/userMixins.js";
import bankTransactionsMixins from "@/components/finances/mixins/bankTransactionsMixins";
import EditEventModulesSection from "../sections/EditEventModulesSection.vue";
import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";

export default {
  name: "EditEventDefaultData",
  props: {
    edit: Boolean,
    event: Object,
    date: String,
  },
  components: { EditEventModulesSection },
  mixins: [bankTransactionsMixins, productSupplierMixins, userMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      eventsType: [{ name: "Evento Pessoal" }],
      paymentMethods: [],
      attachment: "",
      disableEventFields: false,
      autoCompleteDefault: "",
      extraAttachment: "",
      extraAttachment2: "",
      otherAttachments: [],
      swatches: ["#ff7f7e", "#9ad9a1", "#ffc87e"],
      theColor: "",
      priorities: [
        {
          label: "Baixa",
          value: "Baixa",
        },
        {
          label: "Média",
          value: "Média",
        },

        {
          label: "Alta",
          value: "Alta",
        },
      ],
      status: [
        {
          label: "Em aberto",
          value: "Em aberto",
        },
        {
          label: "Cancelado",
          value: "Cancelado",
        },
        {
          label: "Pendente",
          value: "Pendente",
        },
        {
          label: "Pago",
          value: "Pago",
        },
        {
          label: "Realizado",
          value: "Realizado",
        },
        {
          label: "Recebido",
          value: "Recebido",
        },
      ],
      loadingCreateProductSupplier: false,
      loadingCreateEvent: false,
      openCreateProductSupplier: false,
      loading: false,
      companiesList: [],
      companyBanksList: [],
      companyCreditCardList: [],
      companyBranchesList: [],
      activeCollapse: 1,
      dataSource: [{ children: [] }],
    };
  },
  beforeMount() {
    if (this.$root.isAdmin()) {
      this.$http
        .get(
          `/category-entry/list?page=1&per_page=10&category_id=13&order-by=name&order=ascend`
        )
        .then(({ data }) => {
          this.eventsType = data.data;
        });
    }

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=10&category_id=14&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.paymentMethods = data.data;
      });
  },
  mounted() {
    this.productSupplier.pagination.perPage = 9999;
    this.getProductSuppliers();

    if (this.edit)
      this.disableEventFields = ["Pago", "Recebido", "Realizado"].includes(
        this.event.status
      );

    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;

      if (this.edit) {
        setTimeout(() => {
          this.getCompanyBranches(this.event.raw.company_id);

          this.attachment = this.event.raw.attachment;
          this.extraAttachment = this.event.raw.extra_attachment;
          this.extraAttachment2 = this.event.raw.extra_attachment_2;
          this.otherAttachments = this.event.raw.other_attachments
            ? JSON.parse(this.event.raw.other_attachments)
            : [];

          this.getBankAccounts(this.event.raw.company_id);

          if (this.event.raw.payment_methods == "Cartão de Crédito") {
            this.getCreditCards(this.event.raw.company_id);
          }

          this.form.setFieldsValue({
            name: this.event.raw.name,
            description: this.event.raw.description,
            type: this.event.raw.type,
            status: this.event.raw.status,
          });

          setTimeout(() => {
            this.form.setFieldsValue({
              module:
                this.event.raw.module == "" ? undefined : this.event.raw.module,
              date: this.event.raw.date,
              due_date:
                this.event.raw.due_date != "0000-00-00"
                  ? this.event.raw.due_date
                  : undefined,
              competence_date:
                this.event.raw.competence_date != "0000-00-00"
                  ? this.event.raw.competence_date
                  : undefined,
              end_date:
                this.event.raw.end_date != "0000-00-00"
                  ? this.event.raw.end_date
                  : undefined,
              company_id: this.event.raw.company_id,
              company_branch_id: this.event.raw.company_branch_id,
              product_supplier_id: this.event.raw.product_supplier_id,
              value:
                this.event.raw.value == "0.00"
                  ? undefined
                  : this.event.raw.value.replace(".", ","),
              voyage_id:
                this.event.raw.voyage_id == 0
                  ? undefined
                  : this.event.raw.voyage_id,
              priority:
                this.event.raw.priority == ""
                  ? undefined
                  : this.event.raw.priority,
              payment_methods:
                this.event.raw.payment_methods == ""
                  ? undefined
                  : this.event.raw.payment_methods,
            });

            setTimeout(() => {
              this.form.setFieldsValue({
                module_id:
                  this.event.raw.module_id == 0
                    ? undefined
                    : this.event.raw.module_id,
              });
            }, 300);
          }, 300);
        }, 300);
      }
    });

    this.$http
      .get(`/company-bank-accounts/list?page=1&per_page=100&status=Ativo`)
      .then(({ data }) => {
        this.companyBanksList = data.data;
      });

    setTimeout(() => {
      this.form.setFieldsValue({
        product_supplier_id: ["0", 0, ""].includes(
          this.event.raw.product_supplier_id
        )
          ? undefined
          : this.event.raw.product_supplier_id,
      });
    }, 3000);
  },
  methods: {
    searchSupplier: _.debounce(function (e) {
      if (this.dataSource[0].children.length > 0) {
        this.dataSource[0].children = [];
        this.this.autoCompleteDefault = "";
      }

      this.loading = true;

      this.$http
        .post(`/product-supplier/list?page=1&per_page=30&s=${e.target.value}`)
        .then(({ data }) => {
          this.dataSource[0].children = data.data;
          this.loading = false;
        })
        .catch(({ response }) => {
          response;
          this.dataSource[0].children = [];
          this.loading = false;
        });
    }, 600),
    getBankAccounts(id) {
      id;
      this.$http
        .get(`/company-bank-accounts/list?page=1&per_page=100&status=Ativo`)
        .then(({ data }) => {
          this.companyBanksList = data.data;
          if (this.edit) {
            setTimeout(() => {
              this.form.setFieldsValue({
                bank_id: this.event.raw.bank_id,
              });
            }, 100);
          }
        });
    },
    getCreditCards(id) {
      this.$http
        .get(
          `/company-credit-card/list?page=1&per_page=100&status=Ativo&company_id=${id}`
        )
        .then(({ data }) => {
          this.companyCreditCardList = data.data;

          if (this.edit) {
            setTimeout(() => {
              this.form.setFieldsValue({
                company_credit_card_id: this.event.raw.company_credit_card_id,
              });
            }, 100);
          }
        });
    },
    onSelectPaymentMethods(payment) {
      this.companyCreditCardList = [];

      if (payment == "Cartão de Crédito")
        this.getCreditCards(this.form.getFieldValue(`company_id`));
    },
    getCompanyBranchesOnChange(id) {
      this.form.setFieldsValue({
        [`company_branch_id`]: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    checkFileType(fileUrl) {
      let flag = false;
      let filePieces = fileUrl.split(".");
      if (
        filePieces[filePieces.length - 1] == "jpg" ||
        filePieces[filePieces.length - 1] == "png"
      )
        flag = true;

      return flag;
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        });
    },
    formatTheDate(date) {
      let theDate = date.split("/");
      return `${theDate[2]}-${theDate[1]}-${theDate[0]}`;
    },
    async getContractsInEvent(invoiceId) {
      const data = await this.$http.get(
        `/contract-v2/list?page=1&per_page=100&invoice_id=${invoiceId}&join-sales=yes&status=concluded&order=desc&order-by=status`
      );

      return data;
    },
    async updateInvoiceStatus(invoiceId, values) {
      this.$http.post("/invoice/update-field", {
        id: invoiceId,
        field: "status",
        value: values.status,
      });

      await this.$http.post("/log/create", {
        user_id: this.$store.state.userData.id,
        module_id: invoiceId,
        module: "invoice",
        action: "update-status",
        description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o status da fatura ID ${this.event.raw.invoice_id} para <b>${values.status}</b>.`,
      });

      if (values.status === "Recebido" || values.status === "Pago") {
        await this.getContractsInEvent(invoiceId).then(({ data }) => {
          data.data.forEach((contract) => {
            if (contract.meta.sale_status !== "Finalizado") {
              let constractData = { id: contract.id };

              if (contract.meta.flight_sections_rows !== undefined) {
                JSON.parse(contract.meta.flight_sections_rows).forEach(
                  (flight) => {
                    constractData[
                      `contract_finances_flight_${flight.id}_audit_status`
                    ] = "Realizado";

                    constractData[
                      `contract_finances_flight_${flight.id}_audit_user_id`
                    ] = this.$store.state.userData.id;
                  }
                );
              }

              constractData.sale_status = "Finalizado";

              this.$http.post(`/contract/update-multi-meta`, constractData);
              this.$http
                .post(`/sale/update-field`, {
                  id: contract.sale,
                  field: "Status",
                  value: "Finalizado",
                })
                .then(() => {
                  this.$http.post("/log/create", {
                    user_id: this.$store.state.userData.id,
                    module_id: contract.sale,
                    module: "sale",
                    action: "conclude-sale",
                    description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} finalizou a venda #${contract.sale}.`,
                  });
                });
            }
          });
        });
      }
    },
    async onFinishEventCrateBankTransaction(values) {
      if (values.module === "product-supplier") {
        values.product_supplier_id = values.module_id;
      } else {
        values.product_supplier_id = values.product_supplier_id ?? 0;
      }

      values.user_id = this.$store.state.userData.id;
      values.event_id = values.id;
      values.type = values.type === "Recebimento" ? "credit" : "debit";
      values.status = "Realizado";
      values.payment_method = values.payment_methods;
      values.value = values.value.replace("-", "");

      if (values.type === "debit")
        values.description = `Débito realizado através do evento ID ${values.id}.`;

      if (values.type === "credit")
        values.description = `Crédito realizado através do evento ID ${values.id}.`;

      this.createBankTransaction(values).then(() => {
        this.$message.success("Transação bancária gerada com sucesso!", 5);
      });
      this.disableEventFields = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.attachment = this.attachment;
        values.extra_attachment = this.extraAttachment;
        values.extra_attachment_2 = this.extraAttachment2;
        values.other_attachments = JSON.stringify(this.otherAttachments);
        values.value = values.value ? values.value.replace(",", ".") : 0;

        values.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        if (["Recebido", "Pago"].includes(values.status)) {
          if (
            [0, "0", "", undefined, "undefined"].includes(
              values.product_supplier_id
            )
          ) {
            // this.$message.warning(
            //   "Você precisa selecionar um fornecedor para continuar."
            // );
            // return;
          }

          if ([0, "0", "", undefined, "undefined"].includes(values.bank_id)) {
            this.$message.warning(
              "Você precisa selecionar um banco para continuar."
            );
            return;
          }
        }

        if (!err) {
          this.loadingCreateEvent = true;

          if (this.edit) {
            values.id = this.event.id;
            values.user_id = this.event.raw.user_id;
            values.invoice_id = this.event.raw.invoice_id;

            if (
              values.status == "Pago" &&
              this.event.raw.payday == "0000-00-00"
            )
              values.payday = new Date();

            this.$http
              .post("/event/update", values)
              .then(({ data }) => {
                this.$message.success(data.message);
                this.loadingCreateEvent = false;

                if (this.event.raw.invoice_id != 0)
                  this.updateInvoiceStatus(this.event.raw.invoice_id, values);

                this.$emit("listEvents", true);

                if (
                  ["Recebido", "Pago"].includes(values.status) &&
                  this.event.bank_id == 0
                )
                  this.onFinishEventCrateBankTransaction(values);
              })
              .catch(({ response }) => {
                this.$message.error(response.data.message);
                this.loadingCreateEvent = false;
              });
          } else {
            values.user_id = this.$store.state.userData.id;

            this.$http
              .post("/event/create", values)
              .then(({ data }) => {
                this.$message.success(data.message);
                this.loadingCreateEvent = false;
                this.$emit("listEvents", true);
              })
              .catch(({ response }) => {
                this.$message.error(response.data.message);
                this.loadingCreateEvent = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
h2
  font-size: 14px
  text-transform: uppercase
  padding: 6px 0
  margin-bottom: 25px
  color: #c45385 !important
  border-bottom: 1px solid #ddd
</style>

<style>
.extra-event {
  border: 1px solid #eee;
  border-radius: 0;
  border: 0 !important;
}

.extra-event .ant-collapse-content-box {
  padding: 20px 0 10px !important;
}

.extra-event .ant-collapse-header {
  border: 0 !important;
  font-size: 12px;
  color: #333 !important;
  background: #fff;
  border-radius: 0 !important;
}

.extra-event .ant-collapse-content {
  border: 0;
  border-top: 1px solid #eee;
}

.extra-event .ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 !important;
}

.extra-event .ant-collapse-header {
  padding: 12px 0 !important;
}
</style>
